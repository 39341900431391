import { Form, notification, Spin, Steps } from "antd";
import { ModalProps, secqueSteps, SecqueType } from "../../utils/constants";
import { InputField } from "../Input/InputField";
import { OptionPicker } from "../forms/CustomSelect";
import ModalShell from "./ModalShell";
import { useEffect, useState } from "react";
import AuthButton from "../Buttons/AuthButton";
import { BackArrowSvg } from "../Icons/Arrow";
import {
  authSecque,
  closeSecqueModal,
  createSecqueQuestion,
  startSecqueSession,
} from "../../../api/secqueApi";
import { useSelector } from "react-redux";
import { BlackButton, PrimaryButton } from "../Buttons/Button";
import { regexValidation } from "../../utils/helper";
import { logoutUser } from "../../../api/authApi";
import { useHistory } from "react-router-dom";

export const SecurityQuestion = ({ show }) => {
  const [form] = Form.useForm();
  const hasSelectedOption = Form.useWatch("labeledValue", form);
  const secqueQuestionList = useSelector((state) => state.secque.list);
  const [step, setStep] = useState({
    question: [],
    tab: 0,
  });
  const [loading, setLoading] = useState(false);
  const [modalTab, setModalTab] = useState(0);
  const history = useHistory();

  const filteredOptions = secqueQuestionList.map((list) => {
    return {
      label: list.question,
      value: list.id,
      disabled: step.question.some((item) => item.id === list.id),
    };
  });

  function onFinish({ labeledValue, ans }) {
    const newValue = {
      id: labeledValue.value,
      ans,
      labeledValue: {
        label: labeledValue.label,
        value: labeledValue.value,
      },
    };

    setStep((prev) => {
      const isExistingQuestion = prev.tab < prev.question.length;

      return {
        question: isExistingQuestion
          ? prev.question.map((prevItem, index) =>
              prev.tab === index ? newValue : prevItem
            )
          : [...prev.question, newValue],
        tab: prev.tab + 1,
      };
    });
    if (step.tab === 4) {
      setModalTab(1);
    }
  }

  async function submit() {
    setLoading(true);
    const modifiedData = step.question.map(({ id, ans }) => ({
      id,
      ans,
    }));
    try {
      await createSecqueQuestion(modifiedData);
      setModalTab(2);
    } catch (err) {
      notification.error({ message: "Failed", description: err.message });
    } finally {
      setLoading(false);
    }
  }

  function back() {
    if (modalTab !== 0) {
      setModalTab(0);
    }
    setStep((prev) => ({ ...prev, tab: prev.tab - 1 }));
  }

  const modalStep = [
    {
      title: "Security Questions",
      text: `Kindly answer 5 of these questions. These information would be 
      required upon login and transaction related actions`,
      content: (
        <Form
          name="secqueQuestion"
          onFinish={onFinish}
          layout="vertical"
          form={form}
          initialValues={step.question?.[step.tab]}
          requiredMark={false}
          autoComplete="off"
        >
          <Form.Item
            name="labeledValue"
            label="Select Question"
            rules={[
              {
                required: true,
                message: "Field is required",
              },
            ]}
          >
            <OptionPicker
              placeholder="Select"
              className="input-height"
              labelInValue
              options={filteredOptions}
              loading={secqueQuestionList.length < 1}
            />
          </Form.Item>
          {hasSelectedOption && (
            <Form.Item
              name="ans"
              rules={[
                {
                  required: true,
                  message: "Field is required",
                },
                regexValidation,
              ]}
              extra={
                <span className="text-[#475467] text-xs">
                  Maximum of 50 characters
                </span>
              }
            >
              <InputField placeholder="Enter Answer" />
            </Form.Item>
          )}

          <p className="text-center text-[#475467]">{step.tab + 1} of 5</p>

          <div className="flex gap-2 mt-8 items-center mt-5 gap-3">
            <PrimaryButton
              className="w-full !bg-[#F04438]"
              onClick={() => logoutUser(history)}
            >
              Logout
            </PrimaryButton>
            <BlackButton htmlType="submit" className="w-full">
              Submit
            </BlackButton>
          </div>
        </Form>
      ),
    },
    {
      title: "Preview Security Questions",
      text: `Kindly save these information as they would be required upon login`,
      content: (
        <div className="flex flex-col gap-5">
          {step.question.map((item) => (
            <InputField
              key={item.id}
              label={item.labeledValue.label}
              value={item.ans}
              disabled
            />
          ))}

          <AuthButton
            loading={loading}
            onClick={submit}
            className="w-full mt-5"
          >
            Continue
          </AuthButton>
        </div>
      ),
    },
    {
      title: "Security questions set Successfully",
      text: null,
      content: (
        <div>
          <h3 className="text-[#475467] -mt-8 text-center text-sm font-light">
            These information would be required upon login and transaction
            related actions
          </h3>
          <PrimaryButton className="mt-6 w-full" onClick={closeSecqueModal}>
            Done
          </PrimaryButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    form.resetFields();
  }, [step.tab, form]);

  return (
    <ModalShell
      {...ModalProps}
      centered={false}
      width={450}
      show={show}
      closable={false}
      blur="blur(5px)"
      title={
        <div className="relative rounded-tr-lg rounded-tl-lg text-center p-5">
          {step.tab > 0 && modalTab < 2 ? (
            <BackArrowSvg
              className="absolute top-9 cursor-pointer"
              onClick={back}
            />
          ) : null}
          <h3 className="text-[18px] text-[#021230] font-bold  mt-14 mb-1.5">
            {modalStep[modalTab].title}
          </h3>
          {modalStep[modalTab].text && (
            <p className="text-[#475467] text-sm font-light">
              {modalStep[modalTab].text}
            </p>
          )}
        </div>
      }
    >
      <section>{modalStep[modalTab].content}</section>
    </ModalShell>
  );
};

export const Secque = ({ type, next }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [currentSecQuestion, setCurrentQuestion] = useState(null);
  const [message, setMessage] = useState(null);
  const totalQuestion = currentSecQuestion?.totalQuestion || 0;
  const currentQuestion =
    totalQuestion <= 1
      ? totalQuestion
      : currentSecQuestion?.currentQuestion - 1;

  async function onFinish(values) {
    setLoading(true);
    try {
      const response = await authSecque({
        questionId: currentSecQuestion.id,
        ans: values.ans,
      });
      if (response.statusCode === 226) {
        next();
      } else {
        setCurrentQuestion(response.data);
        form.resetFields();
      }
      setLoading(false);
    } catch (err) {
      setMessage(err.message);
    }
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await startSecqueSession({ type });
        setCurrentQuestion(response.data);
      } catch (err) {
        notification.error({ message: err.message });
      }
      setLoading(false);
    }

    fetchData();
  }, [type]);

  if (message) {
    return <p className="text-center text-[#475467]">{message}</p>;
  }

  if (loading || !currentSecQuestion) {
    return (
      <div className="flex justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Form
      name="secque"
      onFinish={onFinish}
      layout="vertical"
      form={form}
      autoComplete="off"
      requiredMark={false}
    >
      <Form.Item
        name="ans"
        label={currentSecQuestion.question}
        rules={[
          {
            required: true,
            message: "Field is required",
          },
          regexValidation,
        ]}
        extra={
          <div className="mt-5 overflow-x">
            <Steps
              size="small"
              responsive={false}
              current={currentQuestion}
              items={secqueSteps[totalQuestion]}
            />
          </div>
        }
      >
        <InputField placeholder="Enter Answer" />
      </Form.Item>
      <AuthButton htmlType="submit" loading={loading} className="w-full mt-5">
        Continue
      </AuthButton>
    </Form>
  );
};

export const SecurityModal = ({
  close,
  show,
  next,
  type = SecqueType.SETTLEMENT,
}) => {
  return (
    <ModalShell
      {...ModalProps}
      centered={false}
      width={450}
      show={show}
      cancel={close}
      title={
        <div className="rounded-tr-lg rounded-tl-lg text-center p-5">
          <h3 className="text-[18px] text-[#021230] font-bold  mt-14 mb-1.5">
            Security Questions
          </h3>

          <p className="text-[#475467] text-sm font-light">
            Kindly answer accurately otherwise account would be restricted{" "}
          </p>
        </div>
      }
    >
      <Secque next={next} type={type} />
    </ModalShell>
  );
};
