import React from "react";
import Icon from "@ant-design/icons";

const DashboardSvg = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.125 8.125C16.125 6.63316 15.5324 5.20242 14.4775 4.14752C13.4226 3.09263 11.9918 2.5 10.5 2.5C9.00816 2.5 7.57742 3.09263 6.52252 4.14752C5.46763 5.20242 4.875 6.63316 4.875 8.125C4.875 10.4325 6.72125 13.44 10.5 17.0425C14.2788 13.44 16.125 10.4325 16.125 8.125ZM10.5 18.75C5.91625 14.5837 3.625 11.0413 3.625 8.125C3.625 6.30164 4.34933 4.55295 5.63864 3.26364C6.92795 1.97433 8.67664 1.25 10.5 1.25C12.3234 1.25 14.072 1.97433 15.3614 3.26364C16.6507 4.55295 17.375 6.30164 17.375 8.125C17.375 11.0413 15.0837 14.5837 10.5 18.75Z"
        fill="#667085"
      />
      <path
        d="M10.5 10C10.9973 10 11.4742 9.80246 11.8258 9.45083C12.1775 9.09919 12.375 8.62228 12.375 8.125C12.375 7.62772 12.1775 7.15081 11.8258 6.79917C11.4742 6.44754 10.9973 6.25 10.5 6.25C10.0027 6.25 9.52581 6.44754 9.17417 6.79917C8.82254 7.15081 8.625 7.62772 8.625 8.125C8.625 8.62228 8.82254 9.09919 9.17417 9.45083C9.52581 9.80246 10.0027 10 10.5 10ZM10.5 11.25C9.6712 11.25 8.87634 10.9208 8.29029 10.3347C7.70424 9.74866 7.375 8.9538 7.375 8.125C7.375 7.2962 7.70424 6.50134 8.29029 5.91529C8.87634 5.32924 9.6712 5 10.5 5C11.3288 5 12.1237 5.32924 12.7097 5.91529C13.2958 6.50134 13.625 7.2962 13.625 8.125C13.625 8.9538 13.2958 9.74866 12.7097 10.3347C12.1237 10.9208 11.3288 11.25 10.5 11.25Z"
        fill="#667085"
      />
    </svg>
  );
};

const DashboardFilled = (props) => <Icon component={DashboardSvg} {...props} />;

export default DashboardFilled;
