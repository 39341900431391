import { Switch, Route, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import {
  AuthContainer,
  VerificationWrapper,
} from "./common/layout/AuthContainer";
import MainContainer from "./common/layout/MainContainer";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import CacheBuster from "react-cache-buster";
import appPackage from "../package.json";
import PageFallback from "./common/components/Fallback/PageFallback";
import LandingContainer from "./landing/layout/LandingContainer";
import { landingRoutes } from "./common/routes/routes";
import { useState, useEffect } from "react";
import ScrollToTop from "./common/layout/ScrollToTop";

function App() {
  const location = useLocation();
  const isProduction = process.env.NODE_ENV === "production";
  const [openRoutes, setOpenRoutes] = useState([]);

  useEffect(() => {
    let list = landingRoutes.map((item) => item.path);
    setOpenRoutes(list);
  }, []);

  return (
    <CacheBuster
      currentVersion={appPackage.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<PageFallback />}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ScrollToTop />
          <Switch>
            <Route
              path={[
                "/login",
                "/signup-nationality",
                "/signup",
                "/signup-type",
                "/signup-subdomain",
                "/signup-docs",
                "/signup-contact-info",
                "/new-account",
                "/forgot-credential",
                "/reset-password",
                "/reset-password/:token",
                "/registration-success",
                "/secque",
              ]}
            >
              <AuthContainer />
            </Route>
            <Route
              path={[
                "/verify-device",
                "/verify-email/:id",
                "/email-check/:type/:category",
              ]}
            >
              <VerificationWrapper />
            </Route>
            <Route
              path={"/"}
              render={() =>
                openRoutes.includes(location.pathname) ? (
                  <LandingContainer />
                ) : !["login"].includes(location.pathname) ? (
                  <MainContainer />
                ) : null
              }
            />
          </Switch>
        </PersistGate>
      </Provider>
    </CacheBuster>
  );
}

export default App;
