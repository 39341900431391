import { Modal } from "antd";
import React from "react";
import CancelIcon from "../../../assets/svg-v2/cancel-icon.svg";

const ModalShell = ({
  show,
  cancel,
  children,
  loading,
  text,
  closeIcon,
  closable,
  footer,
  className,
  style,
  centered,
  title,
  width = 520,
  maskBgColor = "#00000073",
  destroyOnClose = false,
  blur = "blur(2px)",
  maskClosable = true,
}) => {
  return (
    <Modal
      open={show}
      width={width}
      centered={centered}
      title={title ?? title}
      maskStyle={{ background: maskBgColor, backdropFilter: blur }}
      onCancel={cancel}
      okButtonProps={{ loading: loading }}
      okText={text}
      closeIcon={
        closeIcon || (
          <img src={CancelIcon} className="w-6 h-6" alt="close-modal" />
        )
      }
      closable={closable}
      footer={footer ? footer : ""}
      className={className}
      bodyStyle={style}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
    >
      {children}
    </Modal>
  );
};

export default ModalShell;
