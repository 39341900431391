const bvnDataInfo = {
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  middleName: null,
  image: null,
  gender: null,
  phoneNumber: null,
};

const ninDataInfo = {
  firstName: null,
  lastName: null,
  dateOfBirth: null,
  middleName: null,
  image: null,
  gender: null,
  phoneNumber: null,
  address: null,
};

export const userInitalDetails = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  status: "",
  businessName: "",
  businessType: "",
  timeCreated: "",
  gender: "",
  dob: "",
  profileImageId: "",
  tierLevel: "",
  state: "",
  lga: "",
  address: "",
  accountNumber: "",
  bvnVerified: null,
  nin: null,
  ninVerified: null,
  emailVerified: null,
  hasLicense: null,
  licenseDocumentId: null,
  utilityImageId: null,
  utilityImageIdVerified: null,
  identificationImageId: null,
  identificationImageVerified: null,
  placeOfBusinessImageId: null,
  placeOfBusinessImageVerified: null,
  userType: "",
  merchantCategory: null,
  businessRegNo: null,
  cacDocumentId: null,
  taxIdNumber: null,
  hasBusinessVirtualAccount: null,
  accountTypeStatus: null,
  transactionLevelDeclinedMessage: null,
  accountType: null,
  transactionLevel: "",
  transactionLevelUpgradeRequest: null,
  transactionLevelRequestStatus: null,
  kycStatusMessage: null,
  kycStatus: null,
  username: null,
  bank: "",
  accountName: "",
  terminalId: null,
  isTerminalMapped: null,
  totalWalletCredit: 0,
  totalWalletDebit: 0,
  walletBalance: 0,
  mainWalletBalance: 0,
  mainTotalWalletCredit: 0,
  mainTotalWalletDebit: 0,
  commissionWalletBalance: 0,
  commissionTotalWalletCredit: 0,
  commissionTotalWalletDebit: 0,
  commissionBookBalance: 0,
  isCommissionEnabled: null,
  canCreateAggregator: null,
  isSubAggregator: null,
  bvnInfo: bvnDataInfo,
  ninInfo: ninDataInfo,
  lienBy: null,
};

export const userInitalLoginAccount = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  status: "",
  businessName: "",
  businessType: "",
  timeCreated: "",
  gender: "",
  dob: "",
  profileImageId: "",
  state: "",
  lga: "",
  address: "",
  category: "",
  bvnVerified: true,
  nin: null,
  ninVerified: null,
  emailVerified: true,
  utilityImageIdVerified: null,
  identificationImageId: null,
  identificationImageVerified: null,
  placeOfBusinessImageVerified: null,
  username: null,
  businessRegNo: "",
  cacDocumentId: "",
  licenseDocumentId: "",
  hasLicense: true,
  subDomain: null,
  customerSubDomain: "",
  businessSecondaryEmails: [],
  supportPhoneNumber: "",
  splitAccountConfiguration: {
    enabled: false,
    configList: [],
  },
  accountBalanceConfiguration: {
    AGENT: {
      disable: false,
    },
    MERCHANT: {
      disable: false,
    },
    CUSTOMER: {
      disable: false,
    },
  },
};
