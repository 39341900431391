import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { checkCustomerStatus } from "../../utils/helper";

const statusData = {
  active: {
    color: "#067647",
    backgroundColor: "#ECFDF3",
    borderColor: "#ABEFC6",
  },
  mapped: {
    color: "#067647",
    backgroundColor: "#ECFDF3",
    borderColor: "#ABEFC6",
  },
  inactive: {
    color: "#E2164B",
    backgroundColor: "#FDEAEF",
    borderColor: "#ffc5d4",
  },
  expired: {
    color: "#E2164B",
    backgroundColor: "#FDEAEF",
    borderColor: "#D8275599",
  },
  damaged: {
    color: "#E2164B",
    backgroundColor: "#FDEAEF",
    borderColor: "#ffc5d4",
  },
  unmapped: {
    color: "#E2164B",
    backgroundColor: "#FDEAEF",
    borderColor: "#ffc5d4",
  },
  successful: {
    color: "#067647",
    backgroundColor: "#ECFDF3",
    borderColor: "#ABEFC6",
  },
  failed: {
    color: "#E2164B",
    backgroundColor: "#FDEAEF",
    borderColor: "#FECDCA",
  },
  decline: {
    color: "#E2164B",
    backgroundColor: "#FDEAEF",
    borderColor: "#FDDAD7",
  },
  pending: {
    color: "#F06B6B",
    backgroundColor: "#FEF8F1",
    borderColor: "#FCEDD7",
  },
  blocked: {
    color: "#c41d7f",
    backgroundColor: "#fff0f6",
    borderColor: "#ffadd2",
  },
};

const statusIcon = {
  active: <BsCheckLg className="text-lg" />,
  mapped: <BsCheckLg className="text-lg" />,
  inactive: <BsCheckLg className="text-lg" />,
  damaged: <BsCheckLg className="text-lg" />,
  unmapped: <BsCheckLg className="text-lg" />,
  registered: <BsCheckLg className="text-lg" />,
};

export const NewStatus = ({ status, className = "" }) => {
  let styleName;

  const newStatus = status?.toLowerCase() || "";
  switch (newStatus) {
    case "unmapped":
    case "in_active":
    case "inactive":
    case "decline":
    case "damaged":
      styleName = "red";
      break;
    case "pending":
      styleName = "pending";
      break;
    default:
      styleName = "green";
  }

  return (
    <span
      className={`status-card text-center capitalize font-medium ${styleName} ${className}`}
    >
      {newStatus?.replace("_", "")}
    </span>
  );
};

export const TransactionStatus = ({ status = "", className = "" }) => {
  let styleName;

  switch (status?.toLowerCase()) {
    case "successful":
    case "granted":
    case "complete":
    case "approved":
    case "closed":
      styleName = "green";
      break;
    case "payment failed":
    case "declined":
    case "unassigned":
    case "rejected":
    case "failed":
      styleName = "red";
      break;
    case "pending":
    case "payment pending":
      styleName = "pending";
      break;
    case "refunded":
    case "payment refunded":
      styleName = "refund";
      break;
    default:
      styleName = "default";
      break;
  }
  return (
    <span
      className={`status-card capitalize text-center w-max leading-normal font-medium ${styleName} ${className}`}
    >
      {status.toLowerCase()}
    </span>
  );
};

/**
 *
 * @param {{ status: "active" | "inactive"|"successful" | "failed"|"pending" | "expired" | "mapped" | "unmapped", className: string, showIcon: boolean}} param0
 * @returns
 */
export const UserStatus = ({
  status,
  className = "",
  showIcon = true,
  lienBy,
}) => {
  const { statusLowerCase, modifiedStatus } = checkCustomerStatus(
    status,
    lienBy
  );

  return (
    <>
      {status && (
        <div
          className={`p-1 px-2 h-max w-max rounded-2xl text-xs font-semibold leading-normal border-2 gap-1 flex items-center whitespace-nowrap justify-center status ${className}`}
          style={statusData[statusLowerCase]}
        >
          {showIcon && statusIcon[statusLowerCase] && (
            <span>{statusIcon[statusLowerCase]}</span>
          )}
          <p className="h-3 text-xs leading-3 capitalize">{modifiedStatus}</p>
        </div>
      )}
    </>
  );
};
