import React from "react";
import { Skeleton } from "antd";

const PageFallback = () => {
  return (
    <div className="h-full w-full absolute flex items-center justify-center">
      <Skeleton active paragraph={{ rows: 5 }} />
    </div>
  );
};

export default PageFallback;
