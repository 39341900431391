export const authTypes = {
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT USER",
  SET_USER: "SET USER",
  FORM_INPUT: "FORM INPUT",
  SET_EXPIRATION: "OTP EXPIRATION TIME",
  SET_BROWSER_REQUEST_ID: "SET BROWSER REQUEST ID",
  SET_SECRET_KEY: "SET SECRET KEY",
  UPDATE_USER: "UPDATE_USER",
  CACHE_USER: "CACHE_USER",
};

export const transactionStoreTypes = {
  SET_TRANSACTION_TYPES: "SET TRANSACTION TYPES",
  SET_TRANSACTION_STATUS: "SET TRANSACTION STATUS",
  SET_BANK_LIST: "SET_BANK_LIST",
};

export const payoutTypes = {
  PAYOUT_REFERENCE: "PAYOUT REFERENCE",
  CLOSE_PAYOUT_REFERENCE: "CLOSE_PAYOUT_REFERENCE",
};

export const terminalTypes = {
  OPEN_TERMINAL_MODAL: "OPEN TERMINAL MODAL",
  CLOSE_TERMINAL_MODAL: "CLOSE TERMINAL MODAL",
};

export const walletTypes = {
  OPEN_WALLET_OPTION: "OPEN_WALLET_OPTION",
  CLOSE_WALLET_OPTION: "CLOSE_WALLET_OPTION",
  WALLET_TYPE_MODAL: "WALLET_TYPE_MODAL",
  CLOSE_ALL_WALLET_MODAL: "CLOSE_ALL_WALLET_MODAL",
  OPEN_TRANSACTION_MODAL: "OPEN_TRANSACTION_MODAL",
  CLOSE_TRANSACTION_MODAL: "CLOSE_TRANSACTION_MODAL",
};

export const customerTypes = {
  SET_CUSTOMER_METRICS: "SET_CUSTOMER_METRICS",
  SET_TAB: "SET_BUSINESS_TAB",
  TOOGLE_CONTENT: "TOOGLE_CONTENT",
  REFETCH_FORM_RESPONSE: "REFETCH_FORM_RESPONSE",
  REFETCH_CUSTOMER: "REFETCH_CUSTOMER",
  REFETCH_AGGREGATOR_LIST: "REFETCH_AGGREGATOR_LIST",
  WINDOW_SIZE: "WINDOW_SIZE",
  MERCHANT_UPLOAD_STATUS: "MERCHANT_UPLOAD_STATUS",
  TOGGLE_EDIT_MODAL: "TOGGLE_EDIT_MODAL",
};

export const analyticsTypes = {
  DATE_DURATION: "DATE_DURATION",
};

export const loanTypes = {
  EDIT_LOAN: "EDIT_LOAN",
  REFETCH: "REFETCH",
  REFETCH_LOAN_OFFER: "REFETCH_LOAN_OFFER",
};

export const supportTypes = {
  TICKET_CATEGORIES: "TICKET CATEGORIES",
  UPDATE_TICKET_INFO: "UPDATE_TICKET_INFO",
  REFETCH_TICKET: "REFETCH_TICKET",
};

export const settingsActionTypes = {
  FETCH_REQUEST: "FETCH_REQUEST",
  FETCH_USER_BANK_ACCOUNT: "FETCH_USER_BANK_ACCOUNT",
  REFETCH_CUSTOMER_LEVEL: "REFETCH_CUSTOMER_LEVEL",
};

export const subscriptionActionTypes = {
  PREMIUM_BY_TYPE: "PREMIUM_BY_TYPE",
  OPEN_PAYMENT_MODAL: "OPEN_PAYMENT_MODAL",
  CLOSE_PAYMENT_MODAL: "CLOSE_PAYMENT_MODAL",
};

export const sequeActionTypes = {
  SECURITY_QUESTION_MODAL: "SECURITY_QUESTION_MODAL",
  RESTRICTED_ACCOUNT_MODAL: "RESTRICTED_ACCOUNT_MODAL",
};
