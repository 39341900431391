import { loanTypes as types } from "../types";

const initialState = {
  loanData: null,
  payment: {
    list: [],
    requestId: null,
    phoneNumber: null,
  },
  count: 0,
  offerCount: 0,
};

const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_LOAN:
      return { ...state, loanData: action.payload };
    case types.REFETCH:
      return { ...state, count: state.count + 1 };
    case types.REFETCH_LOAN_OFFER:
      return { ...state, offerCount: state.offerCount + 1 };

    default:
      return state;
  }
};

export default loanReducer;
