import React from "react";
import { Select, Spin } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { DropdownArrow } from "../Icons/Arrow";

/**
 * Form Input component
 * @param {String} label
 * @param {String} type i.e "text","email"
 * @param {String} placeholder
 * @param {Boolean} loading i.e indicates loading state
 * @param {Boolean} disabled i.e indicates disabled state
 * @param {Boolean} hasValue i.e indicates input has value
 * @param {Function} onChange. {args:value}
 * @param {children} children i.e react children of antd select options e.g  <Select.Option></Select.Option>
 * @returns react component
 */

export const CustomSelect = ({
  id,
  label,
  name,
  placeholder,
  hasValue,
  loading,
  disabled,
  value,
  onChange,
  children,
  mode,
  defaultValue,
  showSearch,
  onSearch,
  dropdownClassName,
  onPopupScroll,
}) => {
  return (
    <div>
      <Select
        id={id}
        value={value ?? value}
        name={name}
        className={`auth-input ${hasValue ? "filled" : ""} ${
          mode === "multiple" ? "multi" : ""
        }`}
        placeholder={placeholder}
        loading={loading}
        defaultValue={defaultValue ?? defaultValue}
        disabled={disabled}
        onChange={onChange ?? onChange}
        suffixIcon={<CaretDownFilled className="text-base" />}
        mode={mode}
        notFoundContent={loading ? <Spin size="small" /> : null}
        showSearch={showSearch}
        onSearch={onSearch}
        optionFilterProp="children"
        filterOption={(input, option) => {
          if (typeof option.children !== "string") return;
          return (
            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        dropdownClassName={dropdownClassName}
        onPopupScroll={onPopupScroll}
      >
        {children}
      </Select>
      <label className={`auth-label ${hasValue ? "filled" : ""}`}>
        {label}
      </label>
      {loading && !showSearch && (
        <Spin className="absolute right-2 top-5" size="small" />
      )}
    </div>
  );
};

export const OptionPicker = ({
  name,
  placeholder,
  loading = false,
  value,
  onChange,
  defaultValue,
  label,
  rootClassName = "",
  className = "",
  options,
  ...props
}) => {
  const { "aria-required": ariaRequired, ...rest } = props;

  return (
    <div className={` ${rootClassName} flex flex-col gap-2`}>
      {label && <label className="text-[#344054]">{label}</label>}
      <Spin spinning={loading}>
        <Select
          value={value ?? value}
          name={name}
          popupClassName="rounded-xl p-2"
          className={`${className} option-picker`}
          placeholder={placeholder}
          size="large"
          suffixIcon={<DropdownArrow />}
          defaultValue={defaultValue}
          onChange={onChange}
          options={options}
          {...rest}
        />
      </Spin>
    </div>
  );
};
