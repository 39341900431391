import React from "react";
import { PrimaryButton } from "../Buttons/Button";
import { Form, notification } from "antd";
import { InputField } from "../Input/InputField";
import { handleAuthorization } from "../../../api/authApi";

export default function ConfirmTransfer({
  initateTransaction,
  loading,
  setLoading,
}) {
  function submit(values) {
    setLoading(true);
    return handleAuthorization(values)
      .then(() => {
        setLoading(false);
        initateTransaction();
      })
      .catch((error) => {
        setLoading(false);
        notification.error({
          message: "Error",
          description: error.message,
        });
      });
  }

  return (
    <Form requiredMark={false} layout="vertical" onFinish={submit}>
      <Form.Item
        name="password"
        label="Enter Password"
        rules={[{ required: true, message: "Enter password!" }]}
      >
        <InputField.Password name="password" />
      </Form.Item>

      <div className="flex w-full justify-between mt-12">
        <PrimaryButton loading={loading} htmlType="submit" className="w-full">
          Confirm
        </PrimaryButton>
      </div>
    </Form>
  );
}
