import { sequeActionTypes } from "../types";

const initialSecqueModal = {
  showModal: false,
  list: [],
};

const initialState = {
  restrictedAccountModal: false,
  message: {
    title: "",
    successIcon: false,
    text: null,
    timeDelay: 6000,
  },
};

export const secqueReducer = (
  state = initialSecqueModal,
  { type, payload }
) => {
  switch (type) {
    case sequeActionTypes.SECURITY_QUESTION_MODAL:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export const secqueAccountReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case sequeActionTypes.RESTRICTED_ACCOUNT_MODAL:
      return {
        ...state,
        restrictedAccountModal: !state.restrictedAccountModal,
        message: {
          ...state.message,
          text: payload,
          title: "Account Restricted",
        },
      };

    default:
      return state;
  }
};
