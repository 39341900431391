import { analyticsTypes } from "../types";

const initialState = {
  duration: null,
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case analyticsTypes.DATE_DURATION:
      return { ...state, duration: action.payload };

    default:
      return state;
  }
};

export default analyticsReducer;
