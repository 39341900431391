import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const auth = useSelector((s) => s.auth.authenticated);
  return auth ? children : <Redirect to="/login" />;
};

export default ProtectedRoute;
