import React from "react";
import ModalShell from "../Modal/ModalShell";
import BankIcon from "../../../assets/svg-v2/bank-icon.svg";
import SvgIcon from "../../../assets/svg-v2/loan-icon.svg";
import {
  closeWalletModal,
  handleWalletTypeModal,
} from "../../../api/walletApi";
import { useGetAccess } from "../../utils/Hooks";
import { notification } from "antd";
import { useHistory } from "react-router-dom";

const ModalOption = {
  WALLET: "WALLET",
  TRANSFER: "TRANSFER",
};

const TransferOptionModal = ({ show, source = "" }) => {
  const walletType = source.toUpperCase();
  const { disabled, user, isUserAdmin } = useGetAccess();
  const history = useHistory();

  function handleClick() {
    handleWalletTypeModal({ target: ModalOption.WALLET, value: true });
  }

  function handleTransfer() {
    if (user?.settlementBankAccount?.accountNumber) {
      handleWalletTypeModal({
        target: ModalOption.TRANSFER,
        value: true,
      });
    } else {
      let query = "";
      closeWalletModal();
      notification.info({
        message: `You currently don't have a preset settlement account. 
          Set up a settlement bank account to ensure smooth transfer process.`,
      });
      if (isUserAdmin) {
        query = "?tab=4";
      }
      history.push(`/settings${query}`);
    }
  }

  const Option = {
    DISBURSEMENT: (
      <OptionButton
        icon={SvgIcon}
        onClick={handleClick}
        title="Loan Wallet"
        description="Send money to your loan wallet"
      />
    ),
    LOAN: (
      <OptionButton
        icon={SvgIcon}
        onClick={handleClick}
        title="Disbursement Wallet"
        description="Send money to your disbursement wallet"
      />
    ),
    REVENUE: (
      <>
        <OptionButton
          icon={SvgIcon}
          onClick={handleClick}
          title="Disbursement Wallet"
          description="Send money to your disbursement wallet"
        />
      </>
    ),
    SETTLEMENT: (
      <>
        <OptionButton
          icon={SvgIcon}
          onClick={handleClick}
          title="Disbursement Wallet"
          description="Send money to your disbursement wallet"
        />
      </>
    ),
  };

  return (
    <ModalShell
      show={show}
      width={500}
      centered={false}
      cancel={closeWalletModal}
      className="new-modal"
      style={{
        padding: "0px 30px 30px",
      }}
      title={
        <div className="p-6 text-center rounded-tl-lg rounded-tr-lg">
          <h3 className="text-[#021230] text-xl text-center font-extrabold mt-10">
            Transfer Money
          </h3>
          <p className="text-[#475467] text-center font-medium leading-5">
            Choose your preferred option
          </p>{" "}
        </div>
      }
    >
      {!disabled ? (
        <div className="grid md:grid-cols-2 gap-4">
          <OptionButton
            icon={BankIcon}
            onClick={handleTransfer}
            title="Settlement Bank Transfer"
            description="Send money to your settlement bank"
          />
          {Option[walletType]}
        </div>
      ) : (
        <div className="grid grid-cols-1">{Option[walletType]}</div>
      )}
    </ModalShell>
  );
};

function OptionButton({ icon, title, description, onClick }) {
  return (
    <button
      className="p-4 border-2 border-[#F4F7F9] shadow-sm flex flex-col items-center gap-2 rounded-xl"
      onClick={onClick}
    >
      <img src={icon} alt={title} />
      <h3 className="text-[#021230] font-extrabold text-lg">{title}</h3>
      <p className="text-[#475467] text-sm leading-5">{description}</p>
    </button>
  );
}

export default TransferOptionModal;
