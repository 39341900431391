import { settingsActionTypes } from "../types";

const initialState = {
  count: 0,
  sum: 0,
  customerLevelCount: 0,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingsActionTypes.FETCH_REQUEST:
      return { ...state, count: state.count + 1 };

    case settingsActionTypes.FETCH_USER_BANK_ACCOUNT:
      return { ...state, sum: state.sum + 1 };

    case settingsActionTypes.REFETCH_CUSTOMER_LEVEL:
      return { ...state, customerLevelCount: state.customerLevelCount + 1 };

    default:
      return state;
  }
};

export default settingsReducer;
