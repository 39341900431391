import React, { useMemo, useState } from "react";
import ModalShell from "../Modal/ModalShell";
import { Form } from "antd";
import { FormatMoney, capitalize } from "../../utils/helper";
import { v4 } from "uuid";
import { InputField } from "../Input/InputField";
import { PrimaryButton } from "../Buttons/Button";
import ConfirmTransfer from "./ConfirmTransfer";
import { CloseOutlined } from "@ant-design/icons";
import { transferToWallet } from "../../../api/transactionApi";
import { SecqueType } from "../../utils/constants";
import { Secque } from "../Modal/SecurityModal";

const FieldForm = ({ handleResponse }) => {
  const [form] = Form.useForm();

  return (
    <Form
      layout="vertical"
      requiredMark={false}
      form={form}
      onFinish={(values) => handleResponse(values)}
    >
      <Form.Item
        name="amount"
        label="Amount"
        rules={[{ required: true, message: "Enter amount!" }]}
      >
        <InputField.CurrencyInput form={form} placeholder="Amount" />
      </Form.Item>
      <div className="flex w-full mt-12">
        <PrimaryButton htmlType="submit" className="w-full">
          Continue
        </PrimaryButton>
      </div>
    </Form>
  );
};

const WalletTransferModel = ({
  show,
  close,
  setStatus,
  source,
  destination = null,
}) => {
  const [formValue, setFormValue] = useState({
    amount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const uniqueIdentifier = useMemo(() => v4(), []);
  const walletName = destination || source;

  function handleSubmit(value) {
    setFormValue({
      uniqueIdentifier,
      amount: value.amount,
    });
    setStep(1);
  }

  function next() {
    setStep(2);
  }

  async function initateTransaction() {
    setLoading(true);
    try {
      const transactionResponse = await transferToWallet(source, formValue, {
        destinationOption: destination,
      });
      const dataResponse = transactionResponse.data;
      close();
      if (dataResponse.transactionStatus.toLowerCase() === "failed") {
        setStatus({
          status: "Failed",
          message: "Error sending money",
          description: `Please check your network connection or ${source} wallet funds`,
          data: dataResponse,
          open: true,
          isSuccess: false,
        });
      } else {
        setStatus({
          status: "Success",
          message: "Transaction in progress",
          description: `You successfully paid <span className="font-semiblod>"${FormatMoney(
            formValue.amount
          )}</span> in to your
            ${walletName} wallet`,
          data: dataResponse,
          open: true,
          isSuccess: true,
        });
      }
    } catch (error) {
      setStatus({
        status: "Failed",
        message: "Error sending money",
        description: error.message,
        data: null,
        open: true,
        isSuccess: false,
      });
    }
    setLoading(false);
  }

  const walletStep = [
    {
      title: `Transfer to ${capitalize(walletName)} Wallet`,
      text: ` Please enter amount to continue your transfer to your ${walletName} wallet`,
      content: <FieldForm handleResponse={handleSubmit} />,
    },
    {
      title: "Security Questions",
      text: "Kindly answer accurately otherwise account would be restricted",
      content: <Secque next={next} type={SecqueType.TRANSACTION} />,
    },
    {
      title: "Confirm Transfer",
      text: `To confirm your transfer of ${FormatMoney(
        formValue.amount
      )} to your
      ${walletName} wallet, please provide your account password`,
      content: (
        <ConfirmTransfer
          setLoading={setLoading}
          loading={loading}
          initateTransaction={initateTransaction}
        />
      ),
    },
  ];

  return (
    <ModalShell
      style={{
        padding: "0px 30px 30px",
      }}
      width={400}
      show={show}
      centered={false}
      cancel={close}
      closeIcon={<CloseOutlined className="text-black" />}
      className="new-modal"
      title={
        <div className="rounded-tr-lg rounded-tl-lg text-center px-5 pt-5">
          <h3 className="text-[18px] text-[#021230] font-bold  mt-10 mb-1.5">
            {walletStep[step].title}
          </h3>

          <p className="text-[#475467] text-sm font-light">
            {walletStep[step].text}{" "}
          </p>
        </div>
      }
    >
      <section>{walletStep[step].content}</section>
    </ModalShell>
  );
};

export default WalletTransferModel;
