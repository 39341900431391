import React, { memo, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logo/Grupp-logo.svg";
import BackButton from "../../common/components/Buttons/BackButton";

const excludeButton = ["/login", "/signup-nationality"];

const slideShowImg = [
  {
    src: "https://res.cloudinary.com/dbhhyyqd5/image/upload/v1702927092/others/Other_13_nrbdws.webp",
    title: "Comprehensive and Digestible Summary",
    text: "View and download transactions, customer overview, and service summary.",
    className: "py-0",
  },
  {
    src: "https://res.cloudinary.com/dbhhyyqd5/image/upload/v1702927091/others/businessman-holding-smartphone-with-message-bubble-box-golden-bell-alarm-notification-technology-concept-by-3d-render-illustration_1_jsvwtz.webp",
    title: "Synchronized Support Effort",
    text: "Track support efforts via ticket resolution timer, including closed, open, pending, and unassigned tickets.",
    className: "py-5",
  },
  {
    src: "https://res.cloudinary.com/dbhhyyqd5/image/upload/v1702977371/others/1_1_wr3hpi.webp",
    title: "Curated Customer engagement",
    text: "Filter notifications by date, user, message, and type.",
    className: "py-5",
  },
  {
    src: "https://res.cloudinary.com/dbhhyyqd5/image/upload/v1702977372/others/3d-hand-making-cashless-payment-from-smartphone_1_ppsf1u.webp",
    title: "Simplified Payment System",
    text: "Process payments efficiently through multi-channel payments and sort settlements via real time transfers.",
    className: "py-1",
  },
  {
    src: "https://res.cloudinary.com/dbhhyyqd5/image/upload/v1702977374/others/Other_07_wauf9l.webp",
    title: "More Insight. More Access",
    text: "See business insights via terminal health summary and analytics reports.",
    className: "py-1",
  },
];

const ImageSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="image-container">
      {images.map(({ src, title, text, className }, index) => (
        <div
          key={index}
          className={`image-wrapper ${index === currentIndex ? "visible" : ""}`}
        >
          <img
            src={src}
            alt={`screenshot-${index + 1}`}
            className="slider_image"
            loading="lazy"
          />

          <div className={`${className} text-center w-9/12 m-auto`}>
            <h5 className="text-[#021230] text-[24px] font-bold">{title}</h5>
            <p className="text-[#667085]">{text}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

const AuthShell = ({ children }) => {
  const pathname = useLocation().pathname;

  return (
    <div className="grid md:grid-cols-2 relative w-full min-h-screen">
      <div className="w-full h-screen overflow-y-auto">
        <div className="px-5 py-10 max-w-md m-auto">
          <div className="w-[130px] mb-14">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>

          {!excludeButton.includes(pathname) && <BackButton className="mb-5" />}

          {children}
        </div>
      </div>
      <div className="w-full hidden md:flex justify-end onboarding-image relative h-auto">
        <ImageSlider images={slideShowImg} />
      </div>
      <div className="bottom-5 left-5 text-slate-600 text-sm font-medium absolute leading-tight">
        © Grupp 2024
      </div>
    </div>
  );
};

export default memo(AuthShell);
