import React from "react";

export const TableArrow = ({ className = "" }) => {
  return (
    <div className={className}>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.8327 7.49935H1.16602M1.16602 7.49935L6.99935 13.3327M1.16602 7.49935L6.99935 1.66602"
          stroke="#475467"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeinejoin="round"
        />
      </svg>
    </div>
  );
};

export const DropdownArrow = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L6 6L11 1"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BackArrowSvg = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 7H1.5M1.5 7L7.5 13M1.5 7L7.5 1"
          stroke="#667085"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
