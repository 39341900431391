import React, { useEffect } from "react";
import ModalShell from "./ModalShell";
import { CloseOutlined } from "@ant-design/icons";
import TransactionSuccessIcon from "../../../assets/svg-v2/transaction-success-icon.svg";
import FailedIcon from "../../../assets/svg-v2/failed_money.svg";
import ReceiptIcon from "../../../assets/svg-v2/receipt-icon.svg";
import AuthButton from "../Buttons/AuthButton";
import { openTransactionModal } from "../../../api/walletApi";
import success from "../../../assets/svg-v2/check.svg";
import failed from "../../../assets/svg-v2/x-icon-failed.svg";

export function TransactionStatusModal({ close, status, hasReference }) {
  const { open, message, description, isSuccess } = status;

  return (
    <ModalShell
      style={{
        padding: "0px 30px 30px",
      }}
      width={400}
      show={open}
      centered={false}
      cancel={close}
      destroyOnClose
      closeIcon={<CloseOutlined className="text-black" />}
      className="new-modal"
      title={
        <div className="flex justify-center rounded-tr-lg rounded-tl-lg text-center px-5 pt-5">
          <img
            src={isSuccess ? TransactionSuccessIcon : FailedIcon}
            alt="transaction-icon-progress"
          />
        </div>
      }
    >
      <section className="text-center mb-3">
        <h3 className="text-[18px] text-[#021230] font-medium">{message}</h3>
        <div
          className="text-[#475467] mb-3 font-bold"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {hasReference && (
          <div className="flex mt-7 justify-center">
            <button
              onClick={openTransactionModal}
              className="px-3 py-1.5 border border-[#F9DBAF] bg-[#FEF6EE] rounded-2xl flex items-center gap-2"
            >
              <img src={ReceiptIcon} alt="Receipt icon" />
              <span className="text-[#DC6803] font-medium text-sm ">
                View Receipt
              </span>
            </button>
          </div>
        )}

        <AuthButton className="mt-6 w-full" onClick={close}>
          Done
        </AuthButton>
      </section>
    </ModalShell>
  );
}

export function StatusModal({
  show,
  close,
  title,
  text,
  centered = false,
  hasJSXElement,
  successIcon = true,
  timeDelay = 5000,
}) {
  useEffect(() => {
    const timer = setTimeout(() => {
      close();
    }, timeDelay);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalShell
      style={{
        padding: "0px 30px 30px",
      }}
      width={400}
      show={show}
      centered={centered}
      cancel={close}
      closeIcon={<CloseOutlined className="text-black" />}
      className="new-modal"
      title={
        <div className="flex justify-center rounded-tr-lg rounded-tl-lg text-center p-5">
          <div className="w-12 h-12 relative flex justify-center items-center bg-white rounded-lg shadow border border-slate-100">
            <img src={successIcon ? success : failed} alt={title} />
          </div>
        </div>
      }
    >
      <section className="text-center mb-3">
        <h3 className="text-xl font-bold">{title}</h3>
        {text && <p className="text-[#475467]">{text}</p>}
        {hasJSXElement ? (
          hasJSXElement
        ) : (
          <AuthButton className="mt-6 w-full" onClick={close}>
            Done
          </AuthButton>
        )}
      </section>
    </ModalShell>
  );
}
