import { store } from "../store";
import HttpRequest from "./httpRequests";
import url from "./url";
import { subscriptionActionTypes } from "../store/types";

const { dispatch } = store;
const Request = new HttpRequest();

export const openPaymentModal = (payload) => {
  return dispatch({
    type: subscriptionActionTypes.OPEN_PAYMENT_MODAL,
    payload,
  });
};

export const closePaymentModal = () => {
  return dispatch({ type: subscriptionActionTypes.CLOSE_PAYMENT_MODAL });
};

export const getSubscriptionPricing = async (type) => {
  try {
    const response = await Request.get(`/${url.subscription}/${type}/plan`);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const getPremiumSubscription = async (query) => {
  try {
    const response = await Request.get(`/${url.subscription}`, query);
    dispatch({
      type: subscriptionActionTypes.PREMIUM_BY_TYPE,
      payload: response.data,
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message:
        error.response?.data?.message || "Failed to fetch subscription list",
    });
  }
};

export const handleFreeTrailByType = async (data) => {
  try {
    const response = await Request.post(
      `/${url.subscription}/free-trial`,
      null,
      data
    );
    dispatch({
      type: subscriptionActionTypes.PREMIUM_BY_TYPE,
      payload: [response.data],
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const handlePayment = async (data) => {
  try {
    const response = await Request.post(
      `/${url.subscription}/subscribe`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Payment Failed",
    });
  }
};

export const UnSubscribePaymentPlan = async (id, payload) => {
  try {
    const response = await Request.put(
      `/${url.subscription}/${id}/unsubscribe`,
      null,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to UnSubsribe",
    });
  }
};

export const toogleRenwal = async (id, payload) => {
  try {
    const response = await Request.put(
      `/${url.subscription}/${id}/status`,
      null,
      payload
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed",
    });
  }
};
