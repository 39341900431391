import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { authRoutes as routes, verificationRoutes } from "../routes/routes";
import AuthShell from "../../modules/Auth/AuthShell";
import { Spin } from "antd";

export const AuthContainer = () => {
  return (
    <Switch>
      <AuthShell>
        <Suspense
          fallback={
            <div className="flex w-full justify-center">
              <Spin style={{ fontSize: 40 }} />
            </div>
          }
        >
          {routes.map((route, i) => (
            <Route
              key={i}
              path={route.path}
              exact={route.exact || false}
              component={route.Component}
            />
          ))}
        </Suspense>
      </AuthShell>
    </Switch>
  );
};

export const VerificationWrapper = () => {
  return (
    <Switch>
      <Suspense fallback={null}>
        {verificationRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            exact={route.exact || false}
            component={route.Component}
          />
        ))}
      </Suspense>
    </Switch>
  );
};
