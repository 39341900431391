import React from "react";
import { Route, Switch } from "react-router-dom";
import PageFallback from "../components/Fallback/PageFallback";
import ProtectedRoute from "../routes/ProtectedRoute";
import { mainRoutes as routes } from "../routes/routes";
import AppShell from "./AppShell";

const MainContainer = () => {
  return (
    <Switch>
      <ProtectedRoute>
        <AppShell>
          <React.Suspense fallback={<PageFallback />}>
            {routes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                exact={route.exact || false}
                component={route.Component}
              />
            ))}
          </React.Suspense>
        </AppShell>
      </ProtectedRoute>
    </Switch>
  );
};

export default MainContainer;
