import React, { useEffect, useState, Fragment } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import LogoutModal from "../components/Modal/LogoutModal";
import { useSelector } from "react-redux";
import {
  fetchBanks,
  fetchTransactionStatus,
  fetchTransactionTypes,
} from "../../api/transactionApi";
import { getTicketCategories } from "../../api/ticketApi";
import {
  closeTransactionModal,
  closeWalletTypeModal,
} from "../../api/walletApi";
import TransferOptionModal from "../components/Wallet/TransferOptionModal";
import TransferMoneyModal from "../components/Wallet/TransferMoneyModal";
import WalletTransferModel from "../components/Wallet/WalletTransferModel";
import { TransactionStatusModal } from "../components/Modal/StatusModal";
import TransactionDetail from "../components/Transaction/TransactionDetail";
import { useDisclose, useGetUser } from "../utils/Hooks";
import { SecurityQuestion } from "../components/Modal/SecurityModal";
import { getSecqueQuestion } from "../../api/secqueApi";

const initialStatus = {
  open: false,
  message: "",
  description: "",
  data: {
    transactionReference: null,
    timeCreated: null,
  },
};

const AppModalContainer = () => {
  const walletModal = useSelector((s) => s.wallet);
  const [status, setStatus] = useState(initialStatus);
  const showTransaction = walletModal.showTransaction;
  const showModal = useSelector((state) => state.secque.showModal);

  function onClose() {
    setStatus((prev) => ({ ...prev, open: false }));
    closeWalletTypeModal();
  }

  return (
    <Fragment>
      {walletModal.isOpen && (
        <TransferOptionModal
          show={walletModal.isOpen}
          source={walletModal.source}
        />
      )}
      {walletModal.TRANSFER && (
        <TransferMoneyModal
          show={walletModal.TRANSFER}
          source={walletModal.source}
          handleStatus={setStatus}
          close={closeWalletTypeModal}
        />
      )}

      {walletModal.WALLET && (
        <WalletTransferModel
          show={walletModal.WALLET}
          source={walletModal.source}
          destination={walletModal.destination}
          close={closeWalletTypeModal}
          setStatus={setStatus}
        />
      )}

      <TransactionStatusModal
        hasReference={Boolean(status.data)}
        status={status}
        close={onClose}
      />

      {showTransaction && (
        <TransactionDetail
          show={showTransaction}
          record={{
            reference: status.data.transactionReference,
            timeCreated: status.data.timeCreated,
          }}
          close={closeTransactionModal}
          shouldHide
        />
      )}
      {showModal && <SecurityQuestion show={showModal} />}
    </Fragment>
  );
};

const AppShell = ({ children }) => {
  const { onToggle, isOpen } = useDisclose();
  const { user: userProfile, isEnabled } = useGetUser();
  const { open = false, className = "" } = useSelector(
    (s) => s.business?.collapse
  );
  const showSecque = useSelector((state) => state.secque.showModal);

  useEffect(() => {
    async function getCachedData() {
      try {
        if (!showSecque) {
          await Promise.all([
            fetchTransactionTypes(isEnabled),
            fetchTransactionStatus(),
            fetchBanks(),
            getTicketCategories(),
          ]);
        } else {
          await getSecqueQuestion();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
    getCachedData();
  }, [isEnabled, showSecque]);

  return (
    <Layout>
      <Sidebar user={userProfile} open={open} />
      <Layout className={`site-layout ${className}`}>
        <Navbar className={className} logout={onToggle} user={userProfile} />
        {!showSecque ? children : null}
      </Layout>
      <LogoutModal show={isOpen} close={onToggle} />

      <AppModalContainer />
    </Layout>
  );
};

export default AppShell;
