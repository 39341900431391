import { authTypes } from "../types";

const initialState = {
  authenticated: false,
  user: {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    approval: "",
    pages: [],
  },
  formInput: {},
  otpExpiration: 1610374821180,
  browserRequestID: "",
  secretKey: "",
  cachedUser: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGIN_USER:
      return { ...state, authenticated: true };

    case authTypes.CACHE_USER:
      return { ...state, cachedUser: action.payload };

    case authTypes.LOGOUT_USER:
      return {
        ...state,
        authenticated: false,
        user: {},
      };

    case authTypes.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };

    case authTypes.SET_USER:
      return { ...state, user: action.payload };

    case authTypes.FORM_INPUT:
      return { ...state, formInput: action.payload };

    case authTypes.SET_EXPIRATION:
      return { ...state, otpExpiration: action.payload };

    case authTypes.SET_BROWSER_REQUEST_ID:
      return { ...state, browserRequestID: action.payload };

    case authTypes.SET_SECRET_KEY:
      return { ...state, secretKey: action.payload.secretKey };

    default:
      return state;
  }
};

export default authReducer;
