import { store } from "../store";
import { authTypes, sequeActionTypes } from "../store/types";
import HttpRequests from "./httpRequests";
import url from "./url";

const { dispatch } = store;
const Request = new HttpRequests();

export const handleSecqueModal = (payload) => {
  dispatch({
    type: sequeActionTypes.SECURITY_QUESTION_MODAL,
    payload,
  });
};

export const toogleRestrictedModal = (data) => {
  dispatch({
    type: sequeActionTypes.RESTRICTED_ACCOUNT_MODAL,
    payload: data,
  });
};

export const closeSecqueModal = () =>
  handleSecqueModal({ showModal: false, list: [] });

export const getSecqueQuestion = async () => {
  try {
    const response = await Request.get(`/${url.secque}`);
    handleSecqueModal({ list: response.data });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed",
    });
  }
};

export const createSecqueQuestion = async (data) => {
  try {
    const response = await Request.post(`/${url.secque}`, null, data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network Error",
    });
  }
};

export const startSecqueSession = async (data) => {
  try {
    const response = await Request.post(`/${url.secque}/session`, null, data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed",
    });
  }
};

export const authSecque = async (data) => {
  try {
    const response = await Request.post(`/${url.secque}/ans`, null, data);
    return Promise.resolve(response);
  } catch (error) {
    const statusCode = error.response.status;

    if (statusCode === 423) {
      dispatch({ type: authTypes.LOGOUT_USER });
      Request.clearData();
      toogleRestrictedModal(error.response.data.message);
    }
    return Promise.reject({
      statusCode,
      message: error.response?.data?.message || "Network Error",
    });
  }
};
