import React from "react";
import Button from "antd/lib/button";
import { useSelector } from "react-redux";

const AuthButton = ({
  className,
  onClick,
  children,
  loading,
  disabled,
  htmlType,
  type,
}) => {
  const user = useSelector((s) => s.auth.user);
  return (
    <Button
      className={`${className} submit-button text-white border-0 ${
        disabled ? "opacity-40" : "opacity-100"
      }`}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType}
      style={{
        color: "white",
        backgroundColor: user?.color?.primaryColor || "#021230",
      }}
    >
      {children}
    </Button>
  );
};

export default AuthButton;
