import React, { useState, useMemo } from "react";
import ModalShell from "../Modal/ModalShell";
import { Form, notification } from "antd";
import { useAccountInfoValidation, useGetUser } from "../../utils/Hooks";
import {
  FormatMoney,
  TransactionType,
  modifyOptionWithImage,
  validateNumber,
} from "../../utils/helper";
import { OptionPicker } from "../forms/CustomSelect";
import {
  getTransactionServiceFee,
  transferFunds,
} from "../../../api/transactionApi";
import { v4 } from "uuid";
import { InputField } from "../Input/InputField";
import { PrimaryButton } from "../Buttons/Button";
import ConfirmTransfer from "./ConfirmTransfer";
import { CloseOutlined } from "@ant-design/icons";
import { Secque } from "../Modal/SecurityModal";
import { SecqueType } from "../../utils/constants";

const FundForm = ({ handleResponse }) => {
  const [loading, setLoading] = useState(false);
  const uniqueIdentifier = useMemo(() => v4(), []);
  const { user } = useGetUser();
  const { form, bankList, status, message, bankName, handleSelection } =
    useAccountInfoValidation({
      fieldName: "accountNumber",
      codeName: "bankCode",
    });

  async function submit(values) {
    setLoading(true);
    try {
      const feeResponse = await getTransactionServiceFee({
        transactionType: TransactionType.TRANSFER,
        amount: values.amount,
      });
      handleResponse({
        ...values,
        fee: feeResponse.data.fee,
        type: TransactionType.TRANSFER,
        uniqueIdentifier,
        bankName,
      });
    } catch (error) {
      notification.error({ message: "Error!", description: error.message });
    }
    setLoading(false);
  }

  return (
    <Form
      form={form}
      initialValues={{
        bankCode: user.settlementBankAccount.bankCode,
        accountNumber: user.settlementBankAccount.accountNumber,
        accountName: user.settlementBankAccount.accountName,
      }}
      layout="vertical"
      onFinish={submit}
      requiredMark={false}
    >
      <Form.Item
        name="bankCode"
        label="Select Bank"
        rules={[{ required: true, message: "Select bank!" }]}
      >
        <OptionPicker
          placeholder="Select Bank"
          options={modifyOptionWithImage(bankList)}
          onChange={handleSelection}
          className="input-height"
          disabled
        />
      </Form.Item>

      <Form.Item
        label="Account Number"
        name="accountNumber"
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "Enter account number!",
            validator: validateNumber,
          },
        ]}
        extra={message && <p className="text-red-500">{message}</p>}
        validateStatus={status}
        hasFeedback
      >
        <InputField max={11} placeholder="Account Number" disabled />
      </Form.Item>

      <Form.Item
        label="Account Name"
        name="accountName"
        rules={[{ required: true, message: "Account Name is required!" }]}
      >
        <InputField disabled placeholder="Account Name" />
      </Form.Item>

      <Form.Item
        name="amount"
        label="Amount"
        validateTrigger="onChange"
        rules={[{ required: true, message: "Enter amount!" }]}
      >
        <InputField.CurrencyInput form={form} placeholder="Amount" />
      </Form.Item>
      <div className="flex w-full mt-12">
        <PrimaryButton
          htmlType="submit"
          className="w-full"
          loading={loading}
          disabled={status !== "success"}
        >
          Next
        </PrimaryButton>
      </div>
    </Form>
  );
};

const TransferMoneyModal = ({ show, close, handleStatus, source }) => {
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  function handleResponse(values) {
    setStep(1);
    setFormValue(values);
  }

  function next() {
    setStep((prev) => prev + 1);
  }

  async function initateTransaction() {
    setLoading(true);
    try {
      const { fee, ...modifiedData } = formValue;

      const dataResponse = await transferFunds(source, modifiedData);

      if (dataResponse.transactionStatus.toLowerCase() === "failed") {
        handleStatus({
          status: "Failed",
          message: "Error sending money",
          description:
            "Please check your network connection or disbursement wallet funds",
          data: dataResponse,
          open: true,
          isSuccess: false,
        });
      } else {
        handleStatus({
          status: "Success",
          message: "Transaction in progress",
          description: `You successfully paid <span style="font-weight: 600">${FormatMoney(
            formValue?.amount
          )}</span> to
          <span style="font-weight: 600; text-transform: capitalize;">${
            formValue?.accountName
          }</span>
          (${formValue?.bankName} ${formValue?.accountNumber})`,
          data: dataResponse,
          open: true,
          isSuccess: true,
        });
      }
      close();
    } catch (error) {
      handleStatus({
        status: "Failed",
        message: "Error sending money",
        description: error.message,
        data: null,
        open: true,
        isSuccess: false,
      });
    }
    setLoading(false);
  }

  const transferStep = [
    {
      title: "Transfer to Settlement bank",
      text: "Please complete the details to confirm your transfer.",
      content: <FundForm handleResponse={handleResponse} />,
    },
    {
      title: "Security Questions",
      text: "Kindly answer accurately otherwise account would be restricted",
      content: <Secque next={next} type={SecqueType.TRANSACTION} />,
    },
    {
      title: "Confirm Transfer",
      text: `To confirm your transfer of <span style="font-weight: 600">${FormatMoney(
        formValue.amount
      )}</span> to
      <span style="font-weight: 600; text-transform: capitalize;">${
        formValue?.accountName
      }</span>, with a service fee <span style="font-weight: 600;">${FormatMoney(
        formValue.fee,
        true
      )}</span>. please provide your account password`,
      content: (
        <ConfirmTransfer
          setLoading={setLoading}
          loading={loading}
          initateTransaction={initateTransaction}
        />
      ),
    },
  ];

  return (
    <ModalShell
      style={{
        padding: "0px 30px 30px",
      }}
      width={400}
      show={show}
      centered={false}
      cancel={close}
      closeIcon={<CloseOutlined className="text-black" />}
      className="new-modal"
      title={
        <div className="rounded-tr-lg rounded-tl-lg text-center p-5">
          <h3 className="text-[18px] text-[#021230] font-bold  mt-14 mb-1.5">
            {transferStep[step].title}
          </h3>

          <div
            className="text-[#475467] text-sm font-light"
            dangerouslySetInnerHTML={{ __html: transferStep[step].text }}
          />
        </div>
      }
    >
      <section>{transferStep[step].content}</section>
    </ModalShell>
  );
};

export default TransferMoneyModal;
