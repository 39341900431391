import { store } from "../store";
import { supportTypes } from "../store/types";
import HttpRequest from "./httpRequests";
import url from "./url";

const { dispatch } = store;

const Request = new HttpRequest();
const baseUrl = url.ticket;

export const setTicketInfoById = (data) => {
  dispatch({ type: supportTypes.UPDATE_TICKET_INFO, payload: data });
};

export const updateTicketStatus = async (data) => {
  try {
    const response = await Request.put(`/${baseUrl}/status`, null, data);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network Error",
    });
  }
};

export const getTicketCategories = async () => {
  try {
    const response = await Request.get(`/${baseUrl}/categories`);
    dispatch({ type: supportTypes.TICKET_CATEGORIES, payload: response.data });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({ message: err.response?.data?.message });
  }
};

export const getTicket = async (ticketId, userId, isCreatedByBanker) => {
  try {
    const response = await Request.get(`/${baseUrl}/${ticketId}/${userId}`, {
      isCreatedByBanker,
    });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Failed to fetch ticket",
    });
  }
};

export const getTicketByBanker = async (ticketId, phoneNumber) => {
  try {
    const response = await Request.get(
      `/${baseUrl}/${ticketId}/${phoneNumber}`
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({ message: err.response?.data?.message });
  }
};

export const fetchTicketComment = async (id) => {
  try {
    const response = await Request.get(
      `/${baseUrl}/${id}/comments`,
      null,
      null,
      10000
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message:
        err.response?.data?.message ||
        "Something went wrong, please refresh page",
    });
  }
};

export const postTicketComment = async (id, data) => {
  try {
    const response = await Request.post(
      `/${baseUrl}/${id}/comment`,
      null,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message:
        err.response?.data?.message ||
        "Something went wrong, please refresh page",
    });
  }
};

export const raiseCustomerTicket = async (data, customerModelId) => {
  try {
    const response = await Request.postFormData(
      `/${baseUrl}/${customerModelId}/create`,
      data
    );
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message:
        err.response?.data?.message ||
        "Something went wrong, please refresh page",
    });
  }
};

/*export const createDelayedCreditForCustomer = async (data) => {
  try {
    const response = await Request.post(
      `/${baseUrl}/delay-credit`,
      null,
      data,
      dispatch
    );
    return Promise.resolve(response);
  } catch (err) {
    if (err.response)
      return Promise.reject({ message: err.response.data.message });
    return Promise.reject({
      message: "Something went wrong, please refresh page",
    });
  }
};*/

export const createChargebackTicket = async (data) => {
  try {
    const response = await Request.post(`/${baseUrl}/chargeback`, null, data);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message:
        err.response?.data?.message ||
        "Something went wrong, please refresh page",
    });
  }
};

export const createDelayedCreditTicket = async (data) => {
  try {
    const response = await Request.post(
      `/${baseUrl}/user/delay-credit`,
      null,
      data
    );
    dispatch({ type: supportTypes.REFETCH_TICKET, payload: data });
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({ message: err.response?.data?.message || "Failed" });
  }
};

export const getAllTickets = async (query) => {
  try {
    return Request.get(`/${baseUrl}`, query);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Network Error",
    });
  }
};

export const getTicketMetrics = async (query) => {
  try {
    const response = await Request.get(`/${baseUrl}/metrics`, query);
    return Promise.resolve(response);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Network Error",
    });
  }
};

export const updateChatById = async (ticketId) => {
  try {
    await Request.put(`/v2/ticket/${ticketId}/priority`);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Network Error",
    });
  }
};

export const getChat = async (query) => {
  try {
    return Request.get(`/v2/ticket/referral`, query);
  } catch (err) {
    return Promise.reject({
      message: err.response?.data?.message || "Network Error",
    });
  }
};
