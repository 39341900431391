import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const BackButton = ({ className, title, prevState }) => {
  const history = useHistory();
  return (
    <Button
      className={
        "flex flex-col gap-1 h-max text-sm mr-1 border-none shadow-none cursor-pointer  mb-0 p-0 " +
        className
      }
      type="ghost"
      onClick={() => (prevState ? prevState() : history.go(-1))}
    >
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 7H1M1 7L7 13M1 7L7 1"
          stroke="#021230"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {title && (
        <span className="font-extrabold text-[#101828] capitalize text-xl">
          {title}
        </span>
      )}
    </Button>
  );
};

export default BackButton;
