import React, { useState } from "react";
import { Input } from "antd";

const { TextArea } = Input;

export const InputField = ({
  id,
  name,
  type,
  placeholder,
  onChange,
  value,
  label,
  onBlur,
  disabled,
  rootClassName = "",
  max = 50,
  className = "",
  icon,
  ...props
}) => {
  return (
    <div className={`${rootClassName} flex flex-col gap-2`}>
      {label && <label className="text-[#344054]">{label}</label>}
      <Input
        value={value ?? value}
        type={type ?? type}
        id={id}
        name={name}
        className={`input-field ${className}`}
        placeholder={placeholder}
        onChange={onChange ?? onChange}
        onBlur={onBlur}
        maxLength={max}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};

const Password = ({
  id,
  name,
  value,
  onChange,
  placeholder = "Enter Password",
}) => {
  return (
    <Input.Password
      value={value ?? value}
      id={id}
      name={name}
      className="input-field"
      placeholder={placeholder}
      onChange={onChange ?? onChange}
      maxLength={30}
      autoComplete="off"
    />
  );
};

const FieldAddOn = ({
  adddon,
  className,
  name,
  value,
  placeholder,
  id,
  onChange,
  onBlur,
  addonBefore,
  maxLength,
}) => {
  return (
    <Input
      value={value ?? value}
      id={id}
      name={name}
      autoComplete="off"
      className={`input-addon ${className}`}
      placeholder={placeholder}
      addonAfter={adddon}
      addonBefore={addonBefore}
      onChange={onChange ?? onChange}
      onBlur={onBlur}
      maxLength={maxLength}
    />
  );
};

const NumberInput = ({
  name,
  placeholder,
  id,
  form,
  value: initalValue = "",
  className = "",
  max = 11,
  disabled,
}) => {
  const [value, setValue] = useState(initalValue);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const isValid = /^-?\d*(\.\d*)?$/.test(inputValue);
    if (inputValue.length <= max && isValid) {
      form.setFieldValue(name, inputValue);
      setValue(inputValue);
    }
  };

  return (
    <Input
      id={id}
      value={value}
      placeholder={placeholder}
      className={`input-field ${className}`}
      onChange={handleInputChange}
      autoComplete="off"
      max={max}
      disabled={disabled}
    />
  );
};

export const TextField = ({
  placeholder,
  onChange,
  value,
  max = 100,
  style,
  rows,
}) => {
  return (
    <TextArea
      value={value ?? value}
      className="input-field text-area"
      placeholder={placeholder}
      maxLength={max}
      onChange={onChange}
      style={style}
      rows={rows}
    />
  );
};

const CurrencyInput = ({
  form,
  placeholder,
  name = "amount",
  value: initalValue = "",
}) => {
  const [newValue, setNewValue] = useState(initalValue);

  const handleInputChange = (e) => {
    const value = e.target.value;

    const inputValue = value.replace(/[^\d.-]/g, "");

    const isValid = /^-?\d*(\.\d*)?$/.test(inputValue);
    if (isValid || inputValue === "" || inputValue === "-") {
      const formattedCurrency =
        "₦ " + inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      setNewValue(formattedCurrency);
      form.setFieldValue(name, inputValue);
    }
  };

  return (
    <Input
      placeholder={placeholder}
      className="input-field"
      onChange={handleInputChange}
      value={newValue}
    />
  );
};

InputField.Password = Password;
InputField.AddOn = FieldAddOn;
InputField.Number = NumberInput;
InputField.TextField = TextField;
InputField.CurrencyInput = CurrencyInput;
