import { PremiumFeatureRecord } from "../../common/utils/constants";
import { subscriptionActionTypes } from "../types";

const initialState = {
  premium: PremiumFeatureRecord,
  paymentModal: false,
  selectedPlan: { category: null },
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case subscriptionActionTypes.OPEN_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: true,
        selectedPlan: action.payload,
      };

    case subscriptionActionTypes.CLOSE_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: false,
      };

    case subscriptionActionTypes.PREMIUM_BY_TYPE:
      const dataResponse = action.payload;

      const modifiedData = dataResponse.reduce((acc, item) => {
        return { ...acc, [item.type]: item };
      }, {});
      return {
        ...state,
        premium: {
          ...state.premium,
          ...modifiedData,
        },
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
