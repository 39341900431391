import { store } from "../store";
import { loanTypes } from "../store/types";
import HttpRequest from "./httpRequests";
import url from "./url";

const { dispatch } = store;
const Request = new HttpRequest();

export const handleEditProduct = (data) => {
  return dispatch({ type: loanTypes.EDIT_LOAN, payload: data });
};

export const getLoanDuration = async () => {
  try {
    return Request.get(`/${url.loan}/product/duration`);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network error",
    });
  }
};

export const getLoanReasonAndPattern = async () => {
  try {
    return Request.get(`/${url.loan}/product/miscellaneous`);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed",
    });
  }
};

export const getLoanTerms = async () => {
  try {
    return Request.get(`/${url.loan}/terms`);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network Error",
    });
  }
};

export const fetchCustomerLoanRequest = async (id) => {
  try {
    return Request.get(`/${url.loan}/request/${id}`);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network Error",
    });
  }
};

export const fetchRepaymentSchedule = async (id) => {
  try {
    return Request.get(`/${url.loan}/${id}/repayment`);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network Error",
    });
  }
};

export const createProduct = async (data) => {
  try {
    return Request.post(`/${url.loan}/product`, null, data);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to Create",
    });
  }
};

export const getLoanProduct = async (query) => {
  try {
    return Request.get(`/${url.loan}/product`, query);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch product",
    });
  }
};

export const updateLoanProduct = async (id, data) => {
  try {
    return Request.put(`/${url.loan}/product/${id}`, null, data);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "failed to update",
    });
  }
};

export const getLoanRequest = async (query) => {
  try {
    return Request.get(`/${url.loan}/request`, query);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.messagev || "Failed to fetch loan request",
    });
  }
};

export const declineLoanRequestById = async (id) => {
  try {
    const response = await Request.put(`/${url.loan}/request/${id}/reject`);
    dispatch({ type: loanTypes.REFETCH });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getCustomerCreditScoreById = async (userId, query) => {
  try {
    const response = await Request.get(
      `/${url.loan}/${userId}/credit-score`,
      query
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to fetch Credit Store ",
    });
  }
};

export const getBankAccountInformationByBankId = async (id, query) => {
  try {
    return Request.get(`/${url.loan}/${id}/account`, query, null);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getRepaymentTransaction = async (query) => {
  try {
    return Request.get(`/${url.loan}/repayment-transactions`, query);
  } catch (error) {}
};

export const creditTransaction = async (query) => {
  try {
    return Request.get(`/${url.loan}/request-transactions`, query);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Network Error",
    });
  }
};

export const customerTransactionBreakdown = async (userId, query) => {
  try {
    const result = await Request.get(
      `/${url.transaction}/${userId}/breakdown`,
      query
    );
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed",
    });
  }
};

export const fetchLoanOverView = async (query) => {
  try {
    return Request.get(`/${url.loan}/metrics`, query);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed",
    });
  }
};

export const deleteLoanProductById = async (id) => {
  try {
    const response = await Request.put(`/${url.loan}/${id}/delete`);
    dispatch({ type: loanTypes.REFETCH_LOAN_OFFER });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Failed to delete ",
    });
  }
};

export const getDebitStatus = async (type, query) => {
  try {
    return Request.get(`/${url.loan}/${type}/debit-status`, query);
  } catch (error) {
    return Promise.reject({ message: error.response.data.message });
  }
};

export const approveLoanRequestById = async (id) => {
  try {
    const response = await Request.put(`/${url.loan}/request/${id}/approve`);
    dispatch({ type: loanTypes.REFETCH });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const updateLoanRequestById = async (id, data) => {
  try {
    const response = await Request.put(
      `/${url.loan}/request/${id}/change-amount`,
      null,
      data
    );
    dispatch({ type: loanTypes.REFETCH });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject({
      message: error.response?.data?.message || "Something went wrong",
    });
  }
};

export const singleDebitCustomerWallet = async (id) => {
  try {
    return Request.put(`/${url.loan}/${id}/debit`);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const bulkDebitCustomerWallet = async (data) => {
  try {
    return Request.put(`/${url.loan}/bulk-debit`, null, data);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};

export const getRepaymemtBreakdownById = async (requestId) => {
  try {
    return Request.get(`/${url.loan}/${requestId}/repayment-breakdown`);
  } catch (error) {
    return Promise.reject({ message: error.response?.data?.message });
  }
};
