import axios from "axios";
import url from "./url";
import { v4 } from "uuid";
import storage from "redux-persist/lib/storage";
import { updateSecque } from "./authApi";

class HttpRequests {
  header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
  };

  formDataHeader = {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("grupp.token")}`,
  };

  request = axios.create({
    baseURL: url.base,
    headers: this.header,
  });

  requestWithoutHeader = axios.create({
    baseURL: url.base,
    headers: {
      "Content-Type": "application/json",
      browserId: this.setBrowserId(),
    },
  });

  requestFormData = axios.create({
    baseURL: url.base,
    headers: this.formDataHeader,
  });

  setBrowserId() {
    if (!localStorage.getItem("grupp.browserId")) {
      return localStorage.setItem("grupp.browserId", v4());
    }
    return localStorage.getItem("grupp.browserId");
  }

  handleError(err) {
    const statusCode = err.response?.status;

    if (err.code === "ECONNABORTED") {
      return Promise.reject(`Request timed out: ${err.message}`);
    }
    if (statusCode === 401) {
      this.clearData();
      this.navigate("login");
      return Promise.reject(err);
    }
    if (statusCode === 425) {
      updateSecque(err.response.data.data);
      this.navigate("secque");
    }
    return Promise.reject(err);
  }

  clearData = () => {
    storage.removeItem("persist:banker-dashboard");
    storage.removeItem("grupp.token");
    localStorage.removeItem("grupp.token");
  };

  navigate(path) {
    window.history.pushState({}, "", `${path}`);
    window.dispatchEvent(new PopStateEvent("popstate"));
  }

  sendRequest = (url, config) => {
    this.request.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    return this.request(url, config)
      .then((response) => {
        return Promise.resolve({
          statusCode: response.status,
          ...response.data,
        });
      })
      .catch((err) => this.handleError(err));
  };

  sendRequestWithoutHeader = async (url, config) => {
    return this.requestWithoutHeader(url, {
      ...config,
      headers: {
        browserId: this.setBrowserId(),
      },
    })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };

  sendFileRequest = (method, url, data) => {
    this.requestFormData.defaults.headers[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("grupp.token")}`;
    this.request.defaults.headers["browserId"] = this.setBrowserId();
    let config = { method: method, data: data };
    return this.requestFormData(url, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => this.handleError(err));
  };

  get = async (url, params, data, timeout) => {
    const config = { method: "GET", params, data, timeout: timeout };
    return this.sendRequest(url, config);
  };

  post = async (url, params, data) => {
    const config = { method: "POST", params, data };
    return this.sendRequest(url, config);
  };

  postWithoutHeader = async (url, data) => {
    const config = { method: "POST", data };
    return this.sendRequestWithoutHeader(url, config);
  };

  postFormData = async (url, data) => {
    return this.sendFileRequest("POST", url, data);
  };

  put = async (url, params, data) => {
    const config = { method: "PUT", params, data };
    return this.sendRequest(url, config);
  };

  delete = (url, params) => {
    return this.sendRequest(url, { method: "DELETE", params });
  };
}

export default HttpRequests;
