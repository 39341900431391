import { supportTypes } from "../types";

const initialState = {
  ticketCategories: [],
  ticket: {
    ticketId: null,
  },
  count: 0,
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case supportTypes.TICKET_CATEGORIES:
      return { ...state, ticketCategories: action.payload };
    case supportTypes.UPDATE_TICKET_INFO:
      return { ...state, ticket: action.payload };
    case supportTypes.REFETCH_TICKET:
      return { ...state, count: state.count + 1 };
    default:
      return state;
  }
};

export default supportReducer;
